import React, { useEffect, useState } from 'react';
import './loadingSpinner.css'

const LoadingSpinner = () => {
  const [hideLoader, setHideLoader] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
        setProgress(prevProgress => prevProgress + 1);
    }, 50);
    const collection = document.getElementById("myId");

    if(collection) {
      clearInterval(interval);
      setTimeout(() => {
        setHideLoader(true)
      },700)
    }

    return () => clearInterval(interval);

  
  }, [progress]);

  return (
    hideLoader ? '' : <div id='spinnerDiv' className="flex h-screen justify-center items-center hideable-div">
    <div id="loading">
<div class="loader">
 <div id="app">
   <div id="percentage">{Math.round(progress < 100 ? progress : '100')}%&nbsp;</div>
   <div id="dot1" class="primary">
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
   </div>
   <div id="dot2" class="secondary">
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
     <div class="dot"></div>
   </div>
 </div>
</div>
</div>
 </div>
  );
};

export default LoadingSpinner;