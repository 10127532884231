import { Accordion, Button } from "react-bootstrap";
import defaultJson from "../../default.json"
import { Component } from "./Component";
import * as Factory from "./Factory";
import * as ModelHandler from "common/src/ModelHandler";

export class Composite extends Component {
	constructor(props) {
		super(props);
		this.state.arrayEdits = 0;
		this.state.needModelAutoLoad = true;
		let path = props.config.jsonPath.split(".");
		let defaultJsonObj = this.props.defaultJsonObj || defaultJson;
		for (let i = 0; i < path.length; i++) {
			if (i != path.length - 1) {
				defaultJsonObj = defaultJsonObj[path[i]];
			} else {
				//we do this so we have a REFERENCE to the values of the json
				this.state.defaultJsonObj = defaultJsonObj[0];
			}
		}
		if (this.state.jsonObj.length > 1) {
			setTimeout(() => this.props.threePlayer.context.toggleSidebarButton(true, "modelToggleButtonNormal", 9), 100);
		}
	}
	render() {
		return <Accordion key={this.state.forceUpdate}>
			<Accordion.Item key={"items"} eventKey={"items"}>
				<Accordion.Header>{this.props.config.displayName}</Accordion.Header>
				<Accordion.Body>
					<Accordion>
						{this.getJsonVariable().map((_json, _index) => {
							if (this.props.config.displayName == "Variants") {
								return this.renderModelAcc(_json, _index);
							} else {
								return this.renderNormalAcc(_json, _index)
							}
						})}
						{<Button onClick={() => this.newArray()} >Add</Button>}
					</Accordion>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	}
	renderModelAcc(_json, _index) {
		if (this.state.needModelAutoLoad && _index == 0 && !ModelHandler.IsModelLoaded(_index)) {
			ModelHandler.LoadModel(_index);
			this.state.needModelAutoLoad = false;
		}
		if (!ModelHandler.IsModelLoaded(_index)) {
			return <>
				{_index}
				<Button
					onClick={() => {
						ModelHandler.LoadModel(_index);
						this.hardRedraw();
					}}
				>Load</Button>
				<Button
					onClick={() => this.deleteArray(_index)}
					style={{
						background: "red"
					}}
				>Delete</Button>
				<br />

			</>
		} else {
			return <Accordion.Item key={_index + "  " + this.state.arrayEdits} eventKey={_index}>
				<Accordion.Header >
					{_index}
				</Accordion.Header>
				<Accordion.Body >
					<Button
						onClick={() => {
							ModelHandler.UnloadActiveModel();
							this.hardRedraw();
						}}
					>Unload</Button>
					{this.props.config.elements.map(
						(_entry) => Factory.create(
							_entry,
							_json,
							this.props.jsonPath + this.props.config.jsonPath + ".",
							this.state.defaultJsonObj)
					)}
				</Accordion.Body>
			</Accordion.Item>;
		}
	}
	renderNormalAcc(_json, _index) {
		return <Accordion.Item key={_index + "  " + this.state.arrayEdits} eventKey={_index}>
			<Accordion.Header >
				{_index}
				<Button
					onClick={() => this.deleteArray(_index)}
					style={{
						background: "red"
					}}
				>Delete</Button>
			</Accordion.Header>
			<Accordion.Body >
				{this.props.config.elements.map(
					(_entry) => Factory.create(
						_entry,
						_json,
						this.props.jsonPath + this.props.config.jsonPath + ".",
						this.state.defaultJsonObj)
				)}
			</Accordion.Body>
		</Accordion.Item>;
	}

	newArray() {
		let toCopy = this.state.jsonObj.length != 0 ? this.state.jsonObj[0] : this.state.defaultJsonObj;
		this.state.jsonObj.push(JSON.parse(JSON.stringify(toCopy)));
		if (this.state.jsonObj.length > 1) {
			this.props.threePlayer.context.toggleSidebarButton(true, "modelToggleButtonNormal", 9);
		}
		this.hardRedraw();
	}
	//works but need to reupdate visual components
	onSubmitIndex(_oldIndex, _newIndexEvent) {
		let newIndex = parseInt(_newIndexEvent.target[0].value);
		if (newIndex >= 0) {
			if (_oldIndex == newIndex) return;

			let elem = this.state.jsonObj.splice(_oldIndex, 1)[0];

			if (_oldIndex < newIndex) {
				newIndex--; //because of the splice, items to the right now shift by 1
			}
			this.state.jsonObj.splice(newIndex, 0, elem);
			console.log("switched from ", _oldIndex, " to ", newIndex);
		}
		this.hardRedraw();
	}
	deleteArray(_index) {
		this.state.jsonObj.splice(_index, 1);
		if (this.state.jsonObj.length <= 1) {
			this.props.threePlayer.context.toggleSidebarButton(false, "modelToggleButtonNormal");
		}
		this.hardRedraw();
	}

	hardRedraw() {
		this.setState({ arrayEdits: this.state.arrayEdits + 1 })
	}

	getJsonVariable() {
		return this.state.jsonObj;
	}

}