import React from "react";
import { baseInputField } from "./BaseInputField";

export class FileInputField extends React.Component {
	constructor(props) {
		super(props);
		this.state = { fileUpload: "" }
		baseInputField.call(this);
	}

	render() {
		return (
			<label>
				{this.props.displayName + "  "}
				<input
					type="file"
					value={this.state.value}
					onChange={this.handleChange}
					accept={this.props.accept}
					ref={(ref) => this.state.fileUpload = ref}
				/>
			</label>
		);
	}
}
