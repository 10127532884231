import { toast } from "react-toastify";
import { Component } from "../Inspector/Component";
import { ServerConnection } from "../ServerConnection";
import { FileInputField } from "./FileInputField";
import FileUploadProgressModal from "../modal/FileUploadProgressModal";
import { Button } from "react-bootstrap";
import sceneConfig from "./../../../src/sceneConfig.json";

export class GoogleFileInputField extends FileInputField {
	//upload states
	READY = 'ready';
	UPLOADING = 'File upload in progress...';
	SUCCESS = 'File upload successfully.';
	ERROR = 'File upload failed';



	constructor(props) {
		super(props);
		this.state.googlePath = null;
		this.state.server = new ServerConnection();
		this.state.modalShow = false;
		this.handleChange = (_event) => {
			this.setState({ googlePath: null });

			const reader = new FileReader();
			let inputFile = this.state.fileUpload.files[0];
			reader.addEventListener('load', _event => {
				this.state.result = _event.target.result;
			});
			reader.readAsDataURL(inputFile);

			let fileName = this.props.index + "-" + inputFile.name;
			fileName = fileName.replace(/([^A-Za-z0-9-_.])/g, '');

			this.state.uploadState = this.UPLOADING;
			this.setState({ modalShow: true });


			this.state.server.tryUploadFile(
				Component.common.client,
				Component.common.clientProductId,
				fileName,
				inputFile,
				() => {
					this.state.googlePath = Component.common.client + "/" + Component.common.clientProductId + "/" + fileName;
					//baseInputField
					this.setState({ value: _event.target.value });
					this.state.uploadState = this.SUCCESS;
					this.setState({ modalShow: false });
					if (this.state.result) {
						this.props.onChange?.call(this, this.state.googlePath, { data: this.state.result, name: fileName });
					} else {
						console.error("local load was slower than google uploading!!!!!! ADD double check");
					}
				},
				() => {
					this.state.uploadState = this.ERROR;
					toast.error(this.ERROR, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					// this.setState({ modalShow: false });
				});
		}
		this.state.uploadState = this.READY;
	}

	render() {
		return <>
			<FileUploadProgressModal
				show={this.state.modalShow}
				onHide={() => this.setState({ modalShow: false })} />
			{super.render()}
			{this.state.googlePath && <>Google path:{this.state.googlePath}</>}
			{this.props.showClearButton && <Button onClick={() => {
				const targetButton = this.props.index;
				this.setState({ googlePath: "", value: "" });
				const defValues = JSON.parse(JSON.stringify(sceneConfig))
				const defaultGraphicalElement = defValues.viewer.graphicalElement;
				if (this.props.index) {
					const elementById = document.getElementById(`${targetButton}img`)
					if (elementById) {
						elementById.src = defaultGraphicalElement[targetButton];
					}
				}
				this.state.googlePath = defaultGraphicalElement[targetButton];
				this.props.onChange.call(this, this.state.googlePath);
			}}>Clear</Button>}
		</>;
	}
}
