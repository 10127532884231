import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditUserModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const { editUser } = useSelector((state) => state.company);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [company, setCompanyname] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [profilePicture, setProfilePicture] = useState([]);
  const [notify, setNotify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);

  useEffect(() => {
    if (editUser._id) {
      setEmail(editUser.email);
      setUsername(editUser.username);
      setRole(editUser.role);
      setCompanyname(editUser?.company?._id);
      setNotify(editUser?.notifyApproval);
    }
  }, [editUser]);

  const HandleEditUser = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("email", email);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("role", role);
    formData.append("company", company);
    formData.append("notifyApproval", notify);
    formData.append("profilePicture", profilePicture);
    const config = {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true,
    };

    if (company.length < 16) {
      toast.error("Select a company", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!role || role === "404") {
      toast.error("Please Select a Role", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      await axios
        .put(`/userroute/update/${editUser._id}`, formData, config)
        .then((res) => {
          if (res.data.success) {
            toast.success(`user update successfully`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setEmail("");
            setUsername("");
            setPassword("");
            setRole("");
            setCompanyname("");
            setProfilePicture("");
            setModalShow();
            setRefetch(!refetch);
            setIsLoading(false);
          }
          if (res.data.code === 11000) {
            toast.error("User name or email already exists", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {
      if (error.message) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsLoading(false);
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update user
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleEditUser}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="mb-3">Update user info</Form.Label>
            <Form.Control
              required
              type="email"
              className="email_filed"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="text"
              value={username}
              placeholder="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Select
            className="mb-3"
            value={company}
            onChange={(e) => setCompanyname(e.target.value)}
            aria-label="Default select example"
          >
            <option value="404">Select Company</option>
            {companyList &&
              companyList.map((company, index) => (
                <option key={company._id} value={company._id}>
                  {company.companyname}
                </option>
              ))}
          </Form.Select>

          <Form.Select
            value={role}
            className="mb-3"
            onChange={(e) => setRole(e.target.value)}
            aria-label="Default select example"
          >
            <option value="404">Select User Role</option>
            <option value="Client">Client</option>
            <option value="Producer">Producer</option>
            <option value="Administrator">Administrator</option>
          </Form.Select>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <span className="notofy_label">
              <span>Notify </span>
              <InputGroup.Checkbox
                checked={notify}
                onChange={() => setNotify(!notify)}
                aria-label="Checkbox for following text input"
              />
            </span>
            <Form.Label className="label_notify_email">
              (Send Email to user if Renderjob approval is required.)
            </Form.Label>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="label_profile_picture">
              Choose Profile Picture (optional)
            </Form.Label>
            <Form.Control
              onChange={(e) => setProfilePicture(e.target.files[0])}
              type="file"
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Update user
          </Button>
        </Form>
      </Modal.Body>
      {isLoading && (
        <div className="submit_area">
          <>
            <Spinner size="lg" animation="border" />
            &nbsp; <span>Adding user...</span>
          </>
        </div>
      )}
    </Modal>
  );
};

export default EditUserModal;
