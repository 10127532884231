import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import uploadProgress from '../../assests/images/resize-upload.gif'

const FileUploadProgressModal = (props) => {
  return (
    <Modal
      {...props}
      size="xs"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='progress_modal'
      backdrop="static"
    >
      <Modal.Body>
        <img className='progress_image' width={350} src={uploadProgress} alt="" />
        <p className='body_concent'>File upload in progress</p>
      </Modal.Body>
    </Modal>
  );
};

export default FileUploadProgressModal;