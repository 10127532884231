import React from "react";
import "./BodyTitle.css";

const BodyTitle = () => {
  return (
    <div className="body_title">
      <h1>
        OSCF-OSAR frontend
      </h1>
    </div>
  );
};

export default BodyTitle;
