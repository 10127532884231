import axios from "axios";
import { debugHierarchy } from "common/Scene";
import React from "react";
import { Accordion, Button } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Factory from "./Components/Inspector/Factory";
import LoadingSpinner from "./Components/Loader/LoadingSpinner";
import { ServerConnection } from "./Components/ServerConnection";
import { ThreePlayer } from "./Components/ThreePlayer";
import CancelModal from "./Components/modal/CancelModal";
import inspectorConfig from "./InspectorConfig.json";
import sceneConfig from "./sceneConfig.json";

function withParams(Component) {
  return (props) => (
    <Component
      {...props}
      nagivate={useNavigate()}
      useSearchParams={useSearchParams()}
      params={useParams()}
    />
  );
}

class EditorApp extends React.Component {
  constructor(props) {
    super(props);
    window.editorApp = this;
    window.DEBUG_LIGHT = false;
    this.state = {
      filePath: "",
      startEditor: false,
      json: null,
      threePlayer: { context: null },
      needsUpdate: 0,
      server: new ServerConnection(),
      productIdField: "",
      loadedProdId: "",
      children: {},
      modalShow: false,
      btnActive: true,
      btnActiveCompany: true,
      btnUpdate: false,
      enableAr: false,
      environmentMap: false,
      createNewButtonText: "Create New",
      createUpdateButtonText: "Update",
      screenHeight: window.innerHeight,
      extraScreenHeight: 0,
      changedCompanyId: "",
      changedProductId: "",
      loaderSpinner: false,
    };

    Factory.init(this.state.threePlayer, this);
  }

  showThree() {
    if (this.state.startEditor && this.state.json) {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1px 1fr 1fr",
          }}
        >
          <div className="blank_area"></div>
          {this.state.loaderSpinner ? <LoadingSpinner /> : ""}
          <ThreePlayer
            glb={this.state.filePath}
            context={this.state.threePlayer}
            json={this.state.json}
          />
          <Accordion defaultActiveKey="Basic Settings">
            {inspectorConfig.category.map((x, i) => this.showCategory(x, i))}
            <div className="player_item_btn">
              {this.props.params.companyId == "create-new" ||
              !this.props.params.companyId ? (
                <Button
                  disabled={
                    this.state.btnActive ||
                    this.state.btnActiveCompany ||
                    this.state.enableAr ||
                    this.state.environmentMap
                  }
                  onClick={() => this.createJson(this.state.json, () => this.props.nagivate('/'))}
                >
                  {this.state.createNewButtonText}
                </Button>
              ) : (
                <Button
                  disabled={
                    this.state.btnUpdate ||
                    this.state.enableAr ||
                    this.state.environmentMap
                  }
                  onClick={() => this.updateJson(this.state.json, this.props.params.objectId, () => this.props.nagivate('/'))}
                >
                  {this.state.createUpdateButtonText}
                </Button>
              )}
              &nbsp;
              <Button
                variant="danger"
                onClick={() => this.setState({ modalShow: true })}
              >
                Cancel
              </Button>
            </div>
          </Accordion>

          <CancelModal
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  showCategory(_config) {
    return (
      <Accordion.Item
        className="editor_control_items"
        key={_config.displayName + this.state.needsUpdate}
        eventKey={_config.displayName}
      >
        <Accordion.Header className="myheaderarea">
          {_config.displayName}
        </Accordion.Header>
        <Accordion.Body
          id={
            _config.displayName.split(" ")[2]
              ? _config.displayName.toLowerCase().split(" ")[2]
              : _config.displayName.split(" ")[0] == "3D"
              ? "product"
              : _config.displayName.toLowerCase().split(" ")[0]
          }
          className={_config.displayName}
        >
          {_config.elements.map((_entry) =>
            Factory.create(_entry, this.state.json, null)
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  componentDidMount() {
    this.setState({ loaderSpinner: true });
    if (this.props.params.companyId) {
      if (this.props.params.companyId == "create-new") {
        this.loadJson(JSON.parse(JSON.stringify(sceneConfig)));
      } else {
        this.state.server.tryGetClientId(
          this.props.params.companyId,
          this.props.params.clientProductId,
          (_x) => {
            this.loadJson(_x);
          }
        );
      }
    } else {
      this.loadJson(JSON.parse(JSON.stringify(sceneConfig)));
    }

    if (this.state.screenHeight > 652) {
      const mesureHeight = this.state.screenHeight - 652;
      if (mesureHeight > 200) {
        this.setState({ extraScreenHeight: mesureHeight });
      } else {
        this.setState({ extraScreenHeight: 200 });
      }
    } else {
      this.setState({ extraScreenHeight: 200 });
    }
  }

  render() {
    const internalStyle = `
    div#lighting {
      max-height: ${this.state.extraScreenHeight}px;
    }
    div#camera {
      max-height: ${this.state.extraScreenHeight}px;
    }
    div#product .accordion-body{
      max-height: ${this.state.extraScreenHeight}px;
    }
    .flex.h-screen.justify-center.items-center {
      position: absolute;
      z-index: 99;
      background: #020202;
      width: 50vw;
      height: 80vh;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
    }
    #mainContainer {
      position: relative;
    }
  `;

    return (
      <div className="full_width">
        <style>{internalStyle}</style>
        <div className="App editor_player">
          {this.props.useSearchParams &&
          this.props.useSearchParams[0].get("debug") == "true" ? (
            <>
              <Button
                style={{ background: "blue" }}
                onClick={() => console.log(this.state.json)}
              >
                Json
              </Button>
              &nbsp;
              <Button
                style={{ background: "green" }}
                // onClick={() => console.log(this.state.threePlayer.context.scene.children, this.state.threePlayer.context.backScene.children)}
                onClick={() =>
                  console.log(
                    debugHierarchy(this.state.threePlayer.context.scene),
                    this.state.threePlayer.context.scene
                  )
                }
              >
                Scene
              </Button>
              &nbsp;
              <Button
                style={{ background: "green" }}
                // onClick={() => console.log(this.state.threePlayer.context.scene.children, this.state.threePlayer.context.backScene.children)}
                onClick={() =>
                  console.log(
                    debugHierarchy(this.state.threePlayer.context.backScene)
                  )
                }
              >
                Back Scene
              </Button>
              &nbsp;
              <Button
                className="text-black"
                style={{ background: "yellow" }}
                // onClick={() => console.log(this.state.threePlayer.context.scene.children, this.state.threePlayer.context.backScene.children)}
                onClick={() =>
                  console.log(
                    debugHierarchy(this.state.threePlayer.context.camera)
                  )
                }
              >
                Camera
              </Button>
            </>
          ) : (
            ""
          )}
          {this.showThree()}
        </div>
      </div>
    );
  }

  loadJson(_json) {
    this.state.json = typeof _json == "object" ? _json : JSON.parse(_json);
    this.setState({
      startEditor: true,
      loadedProdId: this.state.json.clientProductId,
    });
    this.forceHardReconstruct();
  }

  updateJson(_json, _objectId, _onSuccess) {
    // let oldId = this.state.loadedProdId;
    if (this.state.loadedProdId != _json.clientProductId) {
      this.createJson(_json, () => {
        this.deleteJson(_objectId, _onSuccess);
      });
    } else {
      this.state.server.tryPostIdJson(
        _objectId,
        JSON.stringify(_json),
        _onSuccess
      );
    }
    // if (_json.clientProductId) {
    //   this.props.nagivate('/')
    // }
  }
  createJson(_json, _onSuccess) {
    this.state.loadedProdId = _json.clientProductId;
    this.state.server.tryPostNewJson(JSON.stringify(_json), _onSuccess);
    // if (_json.clientProductId) {
    //   this.props.nagivate('/')
    // }
  }

  deleteJson(_id, _onSuccess) {
    this.state.server.tryDeleteClientId(_id, _onSuccess);
  }

  forceHardReconstruct() {
    this.setState({ needsUpdate: this.state.needsUpdate + 1 });
  }

  onProductIdChange(_changedProductId, _changedCompanyId) {
    this.setState({ changedProductId: _changedProductId });

    const companyIdChange = _changedCompanyId || this.state.changedCompanyId;

    // console.log(_changedProductId, this.props.params.clientProductId);
    // console.log('Company',this.props.params.companyId);
    // console.log('Company 2',  companyIdChange);

    const getDefaultId = companyIdChange || this.props.params.companyId;

    const isTrue = () => this.setState({ btnActive: true });
    const isfalse = () => this.setState({ btnActive: false });
    const activeUpdateBtn = () => this.setState({ btnUpdate: true });
    const deactiveUpdateBtn = () => this.setState({ btnUpdate: false });

    const inValid = () =>
      this.setState({ createNewButtonText: "Provide a valid Product ID" });
    const inValidUpdateBnt = () =>
      this.setState({ createUpdateButtonText: "Provide a valid Product ID" });
    const ValidUpdateBnt = () =>
      this.setState({ createUpdateButtonText: "Update" });
    const validID = () => {
      if (this.state.btnActiveCompany) {
        this.setState({ createNewButtonText: "Select a company" });
      } else {
        this.setState({ createNewButtonText: "Create New" });
      }
    };

    if (
      _changedProductId == this.props.params.clientProductId &&
      this.props.params.companyId == getDefaultId
    ) {
      deactiveUpdateBtn();
      this.setState({ createUpdateButtonText: "Update" });
      return;
    } else {
      if (_changedProductId && companyIdChange) {
        axios
          .get(
            `/productroute/verifyProductId/${_changedProductId}/${companyIdChange}`,
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            // console.log(res.data.message);

            function delayedFunction() {
              setTimeout(() => {
                if (res.data.message == false) {
                  inValid();
                  isTrue();
                  activeUpdateBtn();
                  inValidUpdateBnt();
                } else {
                  validID();
                  isfalse();
                  deactiveUpdateBtn();
                  ValidUpdateBnt();
                }
              }, 1000);
            }

            // Call the function to start the 1-second delay
            delayedFunction();
          });
      }
    }
  }
  onCompanyChange(_changedCompanyId) {
    this.setState({ changedCompanyId: _changedCompanyId });
    if (!_changedCompanyId) {
      this.setState({ btnActiveCompany: true });
      this.setState({ createNewButtonText: "Select a company" });
    } else {
      this.onProductIdChange(this.state.changedProductId, _changedCompanyId);
      this.setState({ btnActiveCompany: false });
      if (this.state.btnActive) {
        this.setState({ createNewButtonText: "Provide a valid Product ID" });
      } else {
        this.setState({ createNewButtonText: "Create New" });
      }
    }
  }
  onArChange(enableAugmentedReality) {
    if (this.state.json.viewer.activeAR == true) {
      if (
        !this.state.json.objects[0].mobileGlb &&
        !this.state.json.objects[0].usdz
      ) {
        this.setState({ enableAr: true });
      } else {
        this.setState({ enableAr: false });
      }
    } else {
      this.setState({ enableAr: false });
    }
  }

  onAndoridFileChange(fileAndorid) {
    if (this.state.json.viewer.activeAR == true) {
      if (
        this.state.json.objects[0].mobileGlb &&
        this.state.json.objects[0].usdz
      ) {
        this.setState({ enableAr: false });
      } else {
        this.setState({ enableAr: true });
      }
    } else {
      this.setState({ enableAr: false });
    }
  }

  onIsoFileChange(fileIso) {
    if (this.state.json.viewer.activeAR == true) {
      if (
        this.state.json.objects[0].mobileGlb &&
        this.state.json.objects[0].usdz
      ) {
        this.setState({ enableAr: false });
      } else {
        this.setState({ enableAr: true });
      }
    } else {
      this.setState({ enableAr: false });
    }
  }

  onEnvironmentMapChange(mapChange) {
    if (this.state.json.viewer.lightSetting.enableEnvironmentMap == true) {
      if (!this.state.json.viewer.lightSetting.envMap) {
        this.setState({ environmentMap: true });
      } else {
        this.setState({ environmentMap: false });
      }
    } else {
      this.setState({ environmentMap: false });
    }
  }

  onEnvironmentMapFileChange(mapChangeFile) {
    if (this.state.json.viewer.lightSetting.enableEnvironmentMap == true) {
      if (!this.state.json.viewer.lightSetting.envMap) {
        this.setState({ environmentMap: true });
      } else {
        this.setState({ environmentMap: false });
      }
    } else {
      this.setState({ environmentMap: false });
    }
  }
}

export default withParams(EditorApp);
