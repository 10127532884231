import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Modal.css'

const CancelModal = (props) => {
  const nagivate = useNavigate()
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='cancel_modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Quit editor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Do you really want to quit without saving?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={props.onHide}>No</Button>
        <Button onClick={() => nagivate('/')} variant="danger">Yes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelModal;