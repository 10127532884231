import React from "react";
import { baseInputField } from "./BaseInputField";

export class BoolInputField extends React.Component {
	constructor(props) {
		super(props);
		baseInputField.call(this);
		this.handleChange = this.handleChangeNew.bind(this);
	}

	handleChangeNew(_event) {
		this.props.onChange?.call(this, _event.target.checked);
		this.setState({ value: _event.target.checked });
	}
	render() {
		return (
			<form>
				<label>
					{this.props.displayName + "  "}
					<input
						type="checkbox"
						value={this.state.value}
						checked={this.state.value}
						onChange={this.handleChange}
					/>
				</label>
			</form>
		);
	}
}
