import * as THREE from "three";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { TIFFLoader } from 'three/examples/jsm/loaders/TIFFLoader';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';


export function InitScene(_config) {
	this.scene = new THREE.Scene();
	this.scene.name = "Scene";
	this.backScene = new THREE.Scene();
	this.backScene.name = "Back Scene";
	SetBackBg.call(this, _config);
	this.camera = new THREE.PerspectiveCamera(
		_config.viewer.cameraSetting.fov,
		this.divRef.current.offsetWidth / this.divRef.current.offsetHeight,
		_config.viewer.cameraSetting.nearPlane,
		_config.viewer.cameraSetting.farPlane
	);
	this.camera.name = "Perspective Camera";
	window._THREE_ = THREE;
}

export function SetBackBg(_config, _data) {

	let bgUrl = _config.viewer.graphicalElement.background;
	if (_config.viewer.backgroundTextureEnabled && (bgUrl || _data)) {
		new THREE.TextureLoader().load(_data || bgUrl,
			(_bg) => {
				if (_bg) {
					_bg.encoding = THREE.sRGBEncoding;
					this.scene.background = _bg;
				}
			});
	} else {
		this.backScene.background = null;
		this.scene.background = null;
	}
}

export function SetEnvMap(_obj, _config, _texture) {
	if (_config.viewer.lightSetting.enableEnvironmentMap &&
		(_texture || _config.viewer.lightSetting.envMap)) {
		if (!_texture) {
			let textureUrl = _config.viewer.lightSetting.envMap;
			let onLoad = (_tex) => {
				_tex.mapping = THREE.EquirectangularReflectionMapping;
				_obj.traverse((child) => {
					if (child.material) {
						child.material.envMap = _tex;
						child.material.envMapIntensity = _config.viewer.lightSetting.envMapExposure;
						child.material.needsUpdate = true;
					}
				});
			};
			if (textureUrl.endsWith(".exr")) {
				new EXRLoader().load(textureUrl, onLoad);
			} else if (textureUrl.endsWith(".tiff")) {
				new TIFFLoader().load(textureUrl, onLoad);
			} else if (textureUrl.endsWith(".hdri")) {
				new RGBELoader().load(textureUrl, onLoad);
			} else {
				new THREE.TextureLoader().load(textureUrl, onLoad);
			}
		} else {
			_obj.traverse((child) => {
				if (child.material) {
					child.material.envMap = _texture;
					child.material.envMapIntensity = _config.viewer.lightSetting.envMapExposure;
					child.material.needsUpdate = true;
				}
			});
		}
	} else {
		_obj.traverse((child) => {
			if (child.material) {
				child.material.envMap = null;
				child.material.needsUpdate = true;
			}
		});
	}
}

export function InitRenderer(_config, _isMobile) {
	let customRatio;
	let ratio = window.devicePixelRatio;

	if (ratio > 1) {
		customRatio = ratio;
	} else {
		if (_isMobile) {
			customRatio = ratio * 0.82;
		} else {
			customRatio = _config.viewer.renderer.ratioDivisor;
		}
	}

	this.renderer = new THREE.WebGLRenderer({
		antialias: true,
		preserveDrawingBuffer: false,
		stencil: false,
		powerPreference: "high-performance",
		alpha: true
		//precision:"mediump",
	});
	this.renderer.toneMapping = THREE[_config.viewer.renderer.toneMapping];
	this.renderer.setPixelRatio(customRatio);

	this.renderer.setSize(this.divRef.current.offsetWidth, this.divRef.current.offsetHeight);

	this.renderer.outputEncoding = THREE.sRGBEncoding;
	this.renderer.physicallyCorrectLights = true;
	this.renderer.shadowMap.enabled = true;
	this.renderer.shadowMap.type = THREE[_config.viewer.renderer.shadowMapType];
}

export function UpdateAmbientLight(_enabled, _intensity, _color = 0xffffff) {
	if (_enabled) {
		if (!this.ambientLight) {
			this.ambientLight = new THREE.AmbientLight();
			this.ambientLight.name = 'Ambient_light';
			this.scene.add(this.ambientLight);
		}
		this.ambientLight.intensity = _intensity;
		this.ambientLight.color = new THREE.Color(_color);

	} else {
		this.scene.remove(this.ambientLight);
		this.ambientLight = null;
	}
}

export function UpdateDirectionalLight(_id, _enabled, _intensity,
	_x, _y, _z,
	_castShadow,
	_color = 0xffffff) {
	let light = null;
	if (!this.lights) {
		this.lights = {};
		if (window.DEBUG_LIGHT) this.lightHelpers = {};
	}
	if (!this.lights[_id]) {
		light = new THREE.DirectionalLight(_color);
		light.name = "Studio-Light" + _id;
		light.shadow.camera.near = 0.1;
		light.shadow.camera.far = 500;
		light.shadow.camera.right = 17;
		light.shadow.camera.left = - 17;
		light.shadow.camera.top = 17;
		light.shadow.camera.bottom = - 17;
		light.shadow.mapSize.width = 2048;
		light.shadow.mapSize.height = 2048;
		light.shadow.radius = 2;
		light.shadow.bias = -0.0005;
		this.lights[_id] = light;
		if (window.DEBUG_LIGHT) {
			this.lightHelpers[_id] = new THREE.CameraHelper(light.shadow.camera);
			this.lightHelpers[_id].name = "Helper-Light" + _id;
			this.backScene.add(this.lightHelpers[_id]);
		}

	} else {
		light = this.lights[_id];
		if (!_enabled) {
			this.lights[_id] = null;
			if (window.DEBUG_LIGHT) {
				this.backScene.remove(this.lightHelpers[_id]);
				this.lightHelpers[_id] = null;
			}
		}
	}
	light.intensity = _intensity;
	light.position.set(_x, _y, _z);
	light.castShadow = _castShadow;
	return light;
}

export function UpdateShadowPlane(_opacity, _groundOffsetY, _spotLightPosY, _spotLightFocus, _gltf) {
	if (!this.lights) return;
	let castShadow = false;
	for (const light in this.lights) {
		if (this.lights[light]?.castShadow) {
			castShadow = true;
			break;
		}
	}

	if (castShadow && _gltf) {
		if (!this.shadowPlane) {
			let planeGeometry = new THREE.PlaneGeometry(100, 100, 10, 10);
			let planeMaterial = new THREE.ShadowMaterial();

			this.shadowPlane = new THREE.Mesh(planeGeometry, planeMaterial);
			this.shadowPlane.rotation.x = - Math.PI / 2;
			this.shadowPlane.receiveShadow = true;
			this.shadowPlane.name = "Shadow Plane";
			this.backScene.add(this.shadowPlane);
		}

		this.shadowPlane.position.y = _gltf.position.y + _groundOffsetY;
		if (!this.spotLight) {
			this.spotLight = new THREE.SpotLight(0xffffff);
			this.spotLight.name = "Spotlight";

			this.spotLight.intensity = 0;
			this.spotLight.power = 0; //doesn't actually add any light to the objects, but still casts a shadow
			this.spotLight.decay = 0; //enable shadow to not disappear despite object size
			this.spotLight.castShadow = true;
			this.spotLight.shadow.mapSize.width = 1024;
			this.spotLight.shadow.mapSize.height = 1024;

			this.backScene.add(this.spotLight);
			if (window.DEBUG_LIGHT) {
				this.spotLightHelper = new THREE.CameraHelper(this.spotLight.shadow.camera);
				this.spotLightHelper.name = "SpotLightHelper";
				this.backScene.add(this.spotLightHelper);
			}
		}

		this.spotLight.position.set(0, _spotLightPosY, 0);
		this.spotLight.shadow.focus = _spotLightFocus;

		if (_opacity !== 0) {
			this.shadowPlane.material.opacity = _opacity;
		} else {
			this.shadowPlane.material.opacity = 0.05;
		}
	} else {
		this.backScene.remove(this.spotLight);
		this.spotLight = null;
		if (window.DEBUG_LIGHT) {
			this.backScene.remove(this.spotLightHelper);
			this.spotLightHelper = null;
		}
		this.backScene.remove(this.shadowPlane);
		this.shadowPlane = null;

	}
}

export function UpdateModelShadowCaster(_finalShadowObject, _model) {
	if (!this.backScene || !_model) return;
	if (this.modelShadowCaster) {
		//this.backScene.remove(this.modelShadowCaster);
		this.modelShadowCaster.remove(this.modelShadowCaster.children[0]);
	} else {
		this.modelShadowCaster = new THREE.Object3D();
		this.modelShadowCaster.name = "Model Shadow Caster";
	}

	this.modelShadowCaster.scene = _finalShadowObject;
	this.modelShadowCaster.add(_finalShadowObject);
	this.modelShadowCaster.scene.position.x = _model.position.x;
	this.modelShadowCaster.scene.position.y = _model.position.y;
	this.modelShadowCaster.scene.position.z = _model.position.z;

	this.modelShadowCaster.scene.rotation.x = _model.rotation.x;
	this.modelShadowCaster.scene.rotation.y = _model.rotation.y;
	this.modelShadowCaster.scene.rotation.z = _model.rotation.z;

	this.modelShadowCaster.scene.scale.set(_model.scale.x, _model.scale.y, _model.scale.z);

	this.modelShadowCaster.traverse(function (_obj) {

		if (_obj.name.toString().toLowerCase().indexOf("pinpoint", 0) !== -1) _obj.visible = !window.DEBUG_LIGHT;

		if (_obj.isMesh) {

			_obj.castShadow = true;
			if (!window.DEBUG_LIGHT) {
				_obj.receiveShadow = true;
				_obj.material = _obj.material.clone();
				_obj.material.opacity = 1;
				_obj.material.transparent = true;
				_obj.material.side = THREE.FrontSide;
				_obj.material.depthTest = false;
				_obj.material.depthWrite = false;
				_obj.material.colorWrite = false;
				_obj.material.flatShading = true;

				_obj.material.map = null;
				_obj.material.normalMap = null;
				_obj.material.roughnessMap = null;
				_obj.material.metalnessMap = null;
				_obj.material.emissiveMap = null;
				_obj.material.aoMap = null;
				_obj.material.envMap = null;

				_obj.material.needsUpdate = true;
			}
		}

	});

	this.backScene.add(this.modelShadowCaster)
}

export function debugHierarchy(_obj, _verbose) {
	if (!_obj) return null;
	if (_verbose) return _obj;
	let obj = {};
	obj.name = _obj.name;
	obj.position = _obj.position;
	obj.rotation = _obj.rotation;
	obj.scale = _obj.scale;
	if (_obj.children.length > 0) {
		obj.children = _obj.children.map(x => debugHierarchy(x));
	}
	return obj;
}

let dracoLoader;
function getDracoLoader() {
	if (!dracoLoader) {
		dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderConfig({ type: 'js' });
		dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
	}
	return dracoLoader;
}

let gltfLoader;
export function getGltfLoader() {
	if (!gltfLoader) {
		gltfLoader = new GLTFLoader();
		gltfLoader.setDRACOLoader(getDracoLoader()).setCrossOrigin('anonymous');
	}
	return gltfLoader;
}

export function selectGltfVariant(_gltf, _index) {
	if (_index < 0) return;
	_internalSelectVariant(_gltf.scene, _gltf.parser, _index);
}
function _internalSelectVariant(_scene, _parser, _index) {
	let prevMaterial;
	_scene.traverse(async (_object) => {
		if (!_object.isMesh || !_object.userData.gltfExtensions) return;
		const meshVariantDef = _object.userData.gltfExtensions['KHR_materials_variants'];
		if (!meshVariantDef) return;
		prevMaterial = _object.material;
		if (!_object.userData.originalMaterial) {
			_object.userData.originalMaterial = _object.material;
		}
		const mapping = meshVariantDef.mappings.find((mapping) => {
			return mapping.variants.includes(_index);
		});
		if (mapping) {
			_object.material = await _parser.getDependency('material', mapping.material);
			_parser.assignFinalMaterial(_object);
		} else {
			_object.material = _object.userData.originalMaterial;
		}
		_object.material.envMap = prevMaterial.envMap;
		_object.material.envMapIntensity = prevMaterial.envMapIntensity;
		_object.material.needsUpdate = true
	});

}