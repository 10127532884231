export function baseInputField() {
	if (!this.state) {
		this.state = {}
	}

	if (this.props.value === undefined) {
		this.state.value = "";
	} else {
		this.state.value = this.props.value;
	}

	this.baseHandleChange = (_event) => {
		this.props.onChange?.call(this, _event.target.value);
		this.setState({ value: _event.target.value });
	}
	this.handleChange = this.baseHandleChange;

	this.handleSubmit = (_event) => {
		_event.preventDefault();
		this.props.onSubmit?.call(this, _event, this.state.value);
		this.forceUpdate();
	}
	this.handleKeyPress = this.props.onKeyPress?.bind(this);
}