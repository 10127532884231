import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Components/login/Login";
import RequireAuth from "./hooks/RequireAuth";
import HomePage from './Components/homePage/HomePage'
import Dashboard from "./Components/dashboard/Dashboard";
import AddUser from "./Components/dashboard/AddUser";
import AddCompany from "./Components/dashboard/AddCompany";
import CheckUserAdministrators from "./hooks/CheckUserAdministrators";
import ApiKey from "./Components/dashboard/ApiKey";
import NavBar from "./Components/navbar/NavBar";
import NotFound from "./Components/NotFound/NotFound";
import EditorApp from "./EditorApp";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedOut } from "./features/authSlice";

function App() {
    const [count, setCount] = useState(0)
    const dispatch = useDispatch();
    const { accessToken: stateAccesToken } = useSelector((state) => state.auth);

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios.post(`/userroute/refreshToken`, { withCredentials: true })
    .then((res) => {
      if (res.data) {
        setCount(count + 1)
      } 
    })
    .catch(function (error) {
    if(error.response.status==401){
      clearInterval(intervalId);
      axios.get(`/authroute/logout`, { withCredentials: true })
        .then((res => {
        }))
      dispatch(userLoggedOut());
      localStorage.removeItem("accessToken");
    }
  })
    
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Clear the interval if the component unmounts
    };

  }, [count, stateAccesToken]);

  return (
    <div className="App">
    {/* {location.pathname === '/editor' ? '' : <NavBar />} */}
    <NavBar />
      {/* {location.pathname === '/editor/' || location.pathname.split('/')[1] === 'renderjobs' ? '' : <BodyTitle />} */}
      <Routes>
        <Route path="/editor/:companyId/:clientProductId/:objectId" element={
            <RequireAuth>
            <EditorApp/>
            </RequireAuth>
        } />
        <Route path="/editor" element={
            <RequireAuth>
            <EditorApp/>
            </RequireAuth>
        } />
          <Route path="/" element={
            <RequireAuth>
            <HomePage/>
            </RequireAuth>
        } />
        <Route path="/administration" element={
          <RequireAuth>
            <CheckUserAdministrators>
              <Dashboard />
            </CheckUserAdministrators>
          </RequireAuth>}>
          <Route path="company-new" element={
            <RequireAuth>
              <AddCompany />
            </RequireAuth>
          } />
          <Route path="apikey-new" element={
            <RequireAuth>
              <ApiKey />
            </RequireAuth>
          } />
          <Route index element={<AddUser />} />
        </Route>

        <Route path="/login" element={
          <Login />
        } />
      <Route path="*" element={
         <NotFound/>
        } />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
