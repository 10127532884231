import { toast } from "react-toastify";

export const host = "";

export class ServerConnection {
	get token() {
		return JSON.parse(localStorage.getItem("accessToken"));
	};

	fileUploadLink = "";
	filePath = "";
	clientId = "";

	async tryUploadFile(_clientName, _fileType, _fileName, _file, _onSuccess, _onError) {
		if (!this.token) {
			await this.setToken();
		}

		this.getUploadLink(_clientName, _fileType, _fileName)
			.then(
				(_uploadLink) => {
					this.uploadFilePromise(_uploadLink, _file).then(_onSuccess, _onError);;
				},
				() => _onError())

	}

	setToken(_onSuccess) {
		return new Promise((_resolve, _reject) => {
			let xhr = new XMLHttpRequest();
			xhr.open("POST", host + "/auth/passport");

			xhr.setRequestHeader("Accept", "application/json");
			xhr.setRequestHeader("Content-Type", "application/json");

			xhr.onload = () => {
				this.token = JSON.parse(xhr.responseText).token;
				_onSuccess?.();
				_resolve();
			}
			let data = '{"username": "' + ServerConnection.user + '","password": "' + ServerConnection.pass + '"}';
			xhr.send(data);
		})
	}

	getUploadLink(_clientName = "thomann", _fileType = "icon", _fileName = "test.txt") {
		return new Promise((_resolve, _reject) => {
			let xhr = new XMLHttpRequest();
			xhr.open("GET", host + "/assetsroute/fileUpload/" + _clientName + "/" + _fileType + "/" + _fileName);
			xhr.setRequestHeader("Authorization", "Bearer " + this.token);
			xhr.onload = () => {
				if (xhr.status != 200) {
					xhr.onerror(xhr.status + " " + xhr.statusText);
					_reject(xhr.status + " " + xhr.statusText);
				} else {
					// console.log(xhr.responseText);
					this.fileUploadLink = xhr.responseText;
					_resolve(xhr.responseText);
				}
			}
			xhr.send();
		});
	}

	forceUploadLink(_event, _url) {
		this.fileUploadLink = _url;
	}

	pushFile(...x) {
		this.filePath = x;
		// console.log(x)
	}

	uploadFile() {
		this.uploadFilePromise(this.fileUploadLink, this.filePath);
	}
	uploadFilePromise(_fileUploadLink, _file) {
		return new Promise((_resolve, _reject) => {
			let xhr = new XMLHttpRequest();
			xhr.open("PUT", _fileUploadLink);
			xhr.setRequestHeader("Content-Type", "application/octet-stream");
			xhr.onload = () => {
				if (xhr.status != 200) {
					_reject(xhr.status + " " + xhr.statusText);
				} else {
					_resolve(xhr);
				}
			}
			xhr.send(_file);
		});
	}

	tryPostIdJson(_clientId, _body, _onSuccess) {
		let xhr = new XMLHttpRequest();
		xhr.open("POST", host + "/productroute/update/" + _clientId);
		xhr.setRequestHeader("Authorization", "Bearer " + this.token);
		xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

		xhr.onload = () => {
			// console.log(xhr.responseText);
			// console.log("updated id:", _clientId);
			toast.success(`Product id "${_clientId}" updated successfully`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			_onSuccess?.();
		}
		xhr.onerror = (_x) => {
			console.error(_x, xhr);
		}
		xhr.send(_body);
	}

	tryPostNewJson(_body, _onSuccess) {
		let xhr = new XMLHttpRequest();
		xhr.open("POST", host + `/productroute/new`);
		xhr.setRequestHeader("Authorization", "Bearer " + this.token);
		xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

		xhr.onload = () => {
			const responsJson = JSON.parse(xhr.responseText);
			// console.log(responsJson);
			if (responsJson.clientProductId) {
				toast.success(`Product "${responsJson.clientProductId}" added successfully`, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			} else if (responsJson.code == 11000) {
				toast.error('Product ID already exists', {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			} else {
				toast.error(xhr.responseText, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}

			_onSuccess?.();
		}
		xhr.send(_body);
	}

	tryGetClientId(_companyId, _clientId, _onSuccess, _onError) {
		this.tryGetRoute("GET", "/productroute/" + _companyId + '/' + _clientId, _onSuccess, _onError);
	}

	tryDeleteClientId(_objectId, _onSuccess) {
		this.tryGetRoute("DELETE", "/productroute/" + _objectId, _onSuccess);
	}

	tryGetRoute(_method, _route, _onSuccess, _onError) {
		let xhr = new XMLHttpRequest();
		xhr.open(_method, host + _route);
		xhr.setRequestHeader("Authorization", "Bearer " + this.token);
		xhr.onload = () => {
			if (xhr.status != 200) {
				xhr.onerror(xhr.status + " " + xhr.statusText);
			} else {
				// console.log(xhr.responseText);
				_onSuccess?.(xhr.responseText);
			}
		}
		xhr.onerror = (_error) => {
			console.error(_error);
			_onError?.(_error);
		}
		xhr.send();
	}
}