import { Leaf } from "../../editor/src/Components/Inspector/Leaf";

const data = {
	indexLoaded: -1,
	gltf: [],

}

export function IsModelLoaded(_index) {
	return data.indexLoaded == _index;
}

export function LoadModel(_index) {
	data.indexLoaded = _index;
}

export function UnloadActiveModel() {
	Leaf.listeners.map(_leaf => {
		if (_leaf.state.isModel) {
			// console.log("found model leaf");
		}
	})
	data.indexLoaded = -1;
}

export function OnExitEditor() {
	data.indexLoaded = -1;
	data.threeJs = null;
}