import * as THREE from "three";

export function CacheMaterials(_gltf) {
	if (!this.common) this.common = {};
	if (!this.common.emissive) this.common.emissive = [];
	_gltf.traverse((child) => {
		if (child.material?.emissiveMap) {
			this.common.emissive.push(child.material);
		}
	});
	this.hasEmissive = this.common.emissive.length > 0;
}

export function SetMap(_gltf, _config, _texture, _onDone) {
	if (!this.common?.emissive) CacheMaterials.call(this, _gltf);

	if (!_texture && !_config.emissionMap) {
		Restore.call(this);
		return;
	}

	let loadTex = (_tex) => {
		_tex.anisotropy = 16;
		_tex.flipY = false;
		_tex.needsUpdate = true;

		this.common.emissive.forEach((_child) => {
			if (!_child.emissiveMapOrig) {
				_child.emissiveMapOrig = _child.emissiveMap;
			}
			_child.emissiveMap = _tex;
			_child.needsUpdate = true;
		})
		if (_onDone) _onDone();

	}

	//console.log(typeof _texture);
	if (typeof _texture == Object && _texture.isTexture == true) {
		loadTex(_texture);
	} else {
		const loadEmissiveTexture = new THREE.TextureLoader();
		loadEmissiveTexture.load(_texture || _config.emissionMap, (texture) => {
			loadTex(texture);
		});
	}
}
function Restore() {
	this.common.emissive.forEach((_child) => {
		if (_child.emissiveMapOrig) {
			_child.emissiveMap = _child.emissiveMapOrig;
			_child.needsUpdate = true;
		}
	});
}

export function SetIntensity(_value) {
	this.common.emissive.forEach(_child => {
		_child.emissiveIntensity = _value == true ? 1 : 0;
		_child.needsUpdate = true;
	});
}