import { DropdownField } from "../InputFields/DropdownField";
import { ServerConnection } from "../ServerConnection";
import { Leaf } from "./Leaf"

export class DropdownLeaf extends Leaf {
	constructor(props) {
		super(props);
		if (this.props.config.valuesRoute) {
			let server = new ServerConnection();
			server.tryGetRoute("GET", this.props.config.valuesRoute, (_response) => {
				this.state.values = JSON.parse(_response).company.map(
					(_elem) => {
						return {
							value: _elem._id,
							display: _elem.companyname
						};
					});
				this.checkDefaultValue();
				this.forceUpdate();
			});
		} else {
			this.state.values = this.props.config.values;
			this.checkDefaultValue();
		}
	}

	render() {
		if (this.state.values) {
			return <form><DropdownField
				displayName={this.props.config.displayName}
				value={this.getJsonVariable()}
				values={this.state.values}
				startsWithEmpty={this.props.config.startsWithEmpty}
				onChange={
					_value => {
						this.setVariable(_value);
					}
				}
			/></form>
		} else {
			return <form><label >{this.props.config.displayName + " Loading..."}</label></form>
		}
	}

	checkDefaultValue() {
		if (!this.getJsonVariable() && !this.props.config.startsWithEmpty) {
			this.setJsonVariable(this.state.values[0].value);
		}
	}
}