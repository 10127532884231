
import { BoolInputField } from "../InputFields/BoolInputField";
import { TextInputField } from "../InputFields/TextInputField";
import { GoogleFileInputField } from "../InputFields/GoogleFileInputField";
import { Component } from "./Component";
import * as THREE from "three";
import { RangeInputField } from "../InputFields/RangeInputField";
import { Button } from "react-bootstrap";

export class Leaf extends Component {
	static listeners = [];

	constructor(props) {
		super(props);
		if (props.config.validationFuncBody) {
			this.state.validation = new Function("_value", props.config.validationFuncBody);
		}
		if (props.config.postEditFuncBody) {
			this.state.postEdit = new Function("_obj", "_value", "THREE", props.config.postEditFuncBody).bind(this);
		}
		if (props.config.onValidFuncBody) {
			this.state.onValid = new Function("_value", props.config.onValidFuncBody).bind(this);
		}
		if (this.props.config.receiverId) {
			Leaf.listeners.push(this);
		}
		this.state.key = 0;
	}
	componentDidMount() {
		if (this.state.onValid && this.isValid(this.props.url)) {
			this.state.onValid(this.getJsonVariable());
		}
	}
	componentWillUnmount() {
		if (this.props.config.receiverId) {
			Leaf.listeners.splice(Leaf.listeners.indexOf(this), 1);
		}
	}
	render() {
		switch (this.props.config.type) {
			case "model":
			case "fileInput":
				return <GoogleFileInputField
					key={this.props.config.displayName}
					index={this.state.jsonIndex}
					displayName={this.props.config.displayName + "--" + this.getJsonVariable()}
					accept={this.props.config.typeAccept}
					showClearButton={this.props.config.showClearButton}
					jsonPath={this.props.config.jsonPath}
					url={this.getJsonVariable()}
					onChange={
						(_response, _file) => {
							this.setJsonVariable(_response);
							this.forceUpdate();
							if (this.state.onValid) {
								this.state.onValid(_file);
							}
						}
					}
				/>
			case "imgInput":
				return <GoogleFileInputField
					key={this.props.config.displayName}
					index={this.state.jsonIndex}
					displayName={this.props.config.displayName + "--" + this.getJsonVariable()}
					accept="image/png, image/jpeg"
					showClearButton={this.props.config.showClearButton}
					jsonPath={this.props.config.jsonPath}
					url={this.getJsonVariable()}
					onChange={
						(_response, _img) => {
							this.setJsonVariable(_response);
							this.forceUpdate();
							if (this.state.onValid) {
								this.state.onValid(_img);
							}
						}}
				/>
			case "floatInput":
			case "intInput":
			case "textInput":
				const validStyle = { color: "green" };
				const invalidStyle = { color: "red" };
				return <TextInputField
					key={this.state.key + this.props.config.displayName}
					displayName={this.props.config.displayName}
					value={this.getJsonVariable()}
					onChange={
						_value => {
							let tmpValue = _value;
							if (this.props.config.type == "intInput") {
								tmpValue = parseInt(tmpValue);
								if (isNaN(tmpValue)) return;
							}
							if (this.props.config.type == "floatInput") {
								tmpValue = parseFloat(tmpValue);
								if (isNaN(tmpValue)) return;
							}
							if (!this.isValid(tmpValue)) {
								return;
							}
							this.setVariable(tmpValue);
						}
					}
					validation={this.state.validation}
					inputValidStyle={validStyle}
					inputInvalidStyle={invalidStyle}
				/>;
			case "rangeInput":
				return <RangeInputField
					displayName={this.props.config.displayName}
					value={this.getJsonVariable()}
					min={this.props.config.min}
					max={this.props.config.max}
					step={this.props.config.step}
					onChange={
						_value => {
							this.setVariable(parseFloat(_value));
						}
					}
				/>
			case "boolInput":
				return <BoolInputField
					displayName={this.props.config.displayName}
					value={this.getJsonVariable()}
					onChange={
						_value => {
							this.setVariable(_value);
						}
					}
				/>;
			case "label":
				return <>{this.props.config.displayName}</>
			case "button":
				return <><Button onClick={new Function(this.props.config.onClick).bind(this)}>{this.props.config.displayName}</Button></>
			default:
				console.error(this.props.config.type, "not supported");
				return null;
		}
	}

	sendMessage(_id, _func) {
		//THIS SHOULD BE DOUBLE!! order of mesages should not matter (but it does) so instead of adding complexity, we're adding redundancy
		Leaf.listeners.map(_leaf => _leaf.receiveMessage(_id, _func));
		Leaf.listeners.map(_leaf => _leaf.receiveMessage(_id, _func));
	}

	receiveMessage(_id, _func) {
		if (_id == this.props.config.receiverId) {
			if (this[_func]) {
				this[_func]();
			} else if (_func == "onValid") {
				this.state[_func](this.getJsonVariable());
			} else {
				console.error(_func, " not handled");
			}
		}
	}

	getJsonVariable() {
		return this.state.jsonObj[this.state.jsonIndex];
	}
	setJsonVariable(_value) {
		this.state.jsonObj[this.state.jsonIndex] = _value;
	}

	setVariable(_value) {
		let target = this.props.threePlayer.context;
		if (this.props.config.editorPath) {//some values might not change values in editor,
			let objPath = this.props.config.editorPath.split(".");
			if (objPath.length > 1) {
				let i = 0;
				for (; i < objPath.length - 1; i++) {
					target = target[objPath[i]];
				}
				target[objPath[i]] = _value;
			}
		}
		if (this.state.postEdit) {
			this.state.postEdit.call(this, target, _value, THREE);
		}

		this.setJsonVariable(_value);

		if (this.state.onValid) {
			this.state.onValid(_value);
		}
	}

	updateVariable() {
		this.setState({ key: this.state.key + 1 });
	}

	isValid(_value) {
		return !this.state.validation || this.state.validation(_value);
	}

	onModelLoad() {
		this.state.onValid(this.getJsonVariable());
	}
}