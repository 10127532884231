import React from "react";
import * as THREE from "three";
import * as Common from "common/Scene"
import * as Emissive from "common/src/Emissive"
import * as ModelHandler from "common/src/ModelHandler"
import { OrbitControls } from "common/OrbitalControls";
import { TIFFLoader } from 'three/examples/jsm/loaders/TIFFLoader';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

export class ThreePlayer extends React.Component {
	constructor(props) {
		super(props);
		this.divRef = React.createRef();
		props.context.context = this;
		this.state = {
			textureLoader: new THREE.TextureLoader(),
			sidebarCache: {},
			companyLogo: '',
		}

		this.loadTexture = (_url, _onLoad) => {
			this.state.textureLoader.load(_url,
				(x) => _onLoad(x)
			);
		};
	}

	componentDidMount() {
		if (this.scene) return;

		Common.InitScene.call(this, this.props.json);
		Common.InitRenderer.call(this, this.props.json);

		this.divRef.current.appendChild(this.renderer.domElement);

		const target = new THREE.Vector3(0, 0, -5);
		target.applyMatrix4(this.camera.matrixWorld);

		var animate = () => {
			requestAnimationFrame(animate);
			this.controls?.update();
			if (this.lightContainer && this.camera) {
				this.lightContainer.quaternion.copy(this.camera.quaternion);
			}

			this.renderer.autoClear = true;
			this.renderer.render(this.scene, this.camera);
			this.renderer.autoClear = false;
			this.renderer.render(this.backScene, this.camera);
		};
		animate();

		this.updateAmbientLight();
	}
	componentWillUnmount() {
		ModelHandler.OnExitEditor();
	}
	loadAndReturnModel(_data, _callback) {
		if (_data.startsWith("http")) {
			Common.getGltfLoader().load(_data,
				(_gltf) => {
					_gltf.scene.root = _gltf;
					_callback(_gltf);
				},
				(_progressEvent) => {
					// console.log((_progressEvent.loaded / _progressEvent.total) * 100, "%")
				},
				(_error) => {
					console.error(_error)
				}
			);
		} else if (_data.startsWith("data:")) {
			fetch(_data)
				.then(res => res.blob())
				.then(blob => blob.arrayBuffer())
				.then(arrayBuffer => {
					Common.getGltfLoader().parse(arrayBuffer, "",
						(_gltf) => {
							_gltf.scene.root = _gltf;
							_callback(_gltf);
						},
						(_progressEvent) => {
							// console.log((_progressEvent.loaded / _progressEvent.total) * 100, "%")
						},
						(_error) => {
							console.error(_error)
						}
					);
				})
		} else {
			console.error("unsupported format:", _data, ".");
		}
	}

	addToScene(_gltf) {
		const isLoadComplete =  _gltf.isObject3D
		const targetElement = document.getElementById('spinnerDiv');
		const targetValue = document.getElementById('percentage');
		const element = document.querySelector('.loader');

		if (isLoadComplete) {
			if (targetValue) {
				targetValue.innerText = '100'
			}
			if (element) {
				element.id = 'myId';
			}
			if (targetElement) {
				targetElement.classList.add('hidden');
			}
		}
    
		this.scene.add(_gltf);
		this.gltf = _gltf;
		this.controls = new OrbitControls(this.camera, this.renderer.domElement,
			{
				base: this,
				autoRotate: false,
				playAutoRotation: false,
				resetAutoRotation: () => { },
				gestureSettings: this.props.json.viewer.gestureSetting,
				isMobile: window.isMobile,

			});
		this.gltfShadow = _gltf.clone();
		this.updateShadowPlane();

	}
	
	removeFromScene(_gltf) {
		this.scene.remove(_gltf);
		this.gltf = null;
		this.updateShadowPlane();
	}

	modifyModel(_dimension, _pos, _value) {
		const obj = this.gltf;
		this.modifyTransform(_dimension, _pos, _value, obj);
		if (this.modelShadowCaster) {
			this.modifyTransform(_dimension, _pos, _value, this.modelShadowCaster.scene);
		}
	}
	modifyTransform(_dimension, _pos, _value, _obj) {
		if (typeof _obj === "string") {
			switch (_obj) {
				case "camera":
					_obj = this.camera;
			}
		}

		if (!_obj) {
			// console.log("no object provided");
			return;
		}
		// console.log(_dimension, _pos, _value, _obj);
		switch (_dimension) {
			case "scale":
				_obj.scale.set(_value, _value, _value);
				break;
			case "position":
				if (_pos == "x") _pos = 0;
				if (_pos == "y") _pos = 1;
				if (_pos == "z") _pos = 2;
				_obj.position.setComponent(_pos, _value)
				break;
			case "rotation":
				_obj.rotation[_pos] = _value;
				if (_obj == this.camera) this.lightContainer.quaternion.copy(this.camera.quaternion);
				break;
			default:
				console.log(_dimension, " config not found");
		}
	}
	copyCameraToJson(_subJson) {
		_subJson.viewer.cameraSetting.positionX = this.camera.position.x;
		_subJson.viewer.cameraSetting.positionY = this.camera.position.y;
		_subJson.viewer.cameraSetting.positionZ = this.camera.position.z;
	}

	checkBackground(_value) {
		if (_value) {
			if (_value == "clear") {
				this.state.bg = null;
			} else if (_value.data) {
				this.state.bg = _value.data;
			}
		}
		Common.SetBackBg.call(this, this.props.json, this.state.bg);
	}

	loadCubeTexture(_textureObj, _onLoad) {
		if (_textureObj.name.endsWith(".exr")) {
			new EXRLoader().load(_textureObj.data, _onLoad);
		} else if (_textureObj.name.endsWith(".tiff")) {
			new TIFFLoader().load(_textureObj.data, _onLoad);
		} else if (_textureObj.name.endsWith(".hdri")) {
			new RGBELoader().load(_textureObj.data, _onLoad);
		} else {
			new THREE.TextureLoader().load(_textureObj.data, _onLoad);
		}
	}

	checkEnvMap(_textureObj) {
		if (!this.gltf) return;
		if (_textureObj) {
			if (_textureObj.data == null) {
				this.state.envMap = null;
				Common.SetEnvMap(this.gltf, this.props.json, this.state.envMap);
			} else {
				this.loadCubeTexture(_textureObj, (_tex) => {
					_tex.mapping = THREE.EquirectangularReflectionMapping;
					this.state.envMap = _tex;
					Common.SetEnvMap(this.gltf, this.props.json, this.state.envMap);
				});
			}
		} else {
			Common.SetEnvMap(this.gltf, this.props.json, this.state.envMap);
		}
	}
	updateEmissiveMap(_img) {
		if (!this.gltf) return;
		Emissive.SetMap.call(this, this.gltf, this.props.json.objects[0], _img?.data);
	}
	render() {
		return <div
			id="mainContainer"
			ref={this.divRef}
			style={{
				width: "50vw",
				height: "80vh",
				overflow: "hidden",
				margin: 0,
				border: "solid",
				position: "relative"
			}}
		>
			<div id="logo" style={{
				display: "flex",
				justifyContent: "center",
				position: "absolute",
				top: 0,
				width: "100%",
				zIndex: 100000,
				marginTop: "25px"

			}}>
				<a id="logoHref" href="" target="_blank">
					<img id="brandLogo" src="" class="brandLogo noSelect pointer" style={{
						height: "100px",

					}} /></a>
			</div>
			<div id="menu"
				style={{
					right: 0,
					overflow: "hidden",
					position: "absolute",
					display: "-webkit-box",
					display: "-moz-box",
					display: "-ms-flexbox",
					display: "-webkit-flex",
					display: "flex",
					overflow: "hidden",
					width: "10%",
					height: "100%",
					"align-items": "center",
					"justify-content": "flex-end",
					"-webkit-flex-flow": "row",
					"flex-flow": "row",
					"z-index": 100001
				}}>
				<div class="opacityborder"
					id="toggleMenu"
					display=""
					style={{
						background: "rgba(0, 0, 0, .25)",
						"background-clip": "padding-box",
						border: "8px solid rgba(0, 0, 0, .25)",
						"border-bottom-left-radius": "12px",
						"border-top-left-radius": "12px",
						"padding-right": "7px"
					}}>
					<div id="menuIcons" class="menuIcons" style={{
						"padding-top": "10%",
						display: "flex",
						"flex-direction": "column",
						rowGap: "10px"
					}}>

					</div>
				</div>
			</div>
		</div >
	}

	updateAmbientLight() {
		Common.UpdateAmbientLight.call(this,
			this.props.json.viewer.lightSetting.enableAmbientLight,
			this.props.json.viewer.lightSetting.ambientLightIntensity);
		return;
	}

	updateLight(_id) {
		let jsonValueLower = (_string) => {
			return this.props.json.viewer.lightSetting[_id + "Light" + _string];
		}
		let jsonValueUpper = (_string) => {
			return this.props.json.viewer.lightSetting["enable" + _id.charAt(0).toUpperCase() + _id.slice(1) + "Light" + _string];
		}
		let sceneFunc = "";
		if (!this.lightContainer) {
			this.lightContainer = new THREE.Object3D();
			this.lightContainer.name = "LightContainer"
			this.scene.add(this.lightContainer);
		}

		if (jsonValueUpper("")) {
			if (!this.lights || !this.lights[_id]) sceneFunc = "add";
		} else {
			if (this.lights && this.lights[_id]) sceneFunc = "remove"
		}

		let light = Common.UpdateDirectionalLight.call(this, _id, jsonValueUpper(""), jsonValueLower("Intensity"),
			jsonValueLower("PositionX"), jsonValueLower("PositionY"), jsonValueLower("PositionZ"),
			jsonValueUpper("Shadow"));

		if (sceneFunc) this.lightContainer[sceneFunc](light);

		this.updateShadowPlane();
	};

	updateShadowPlane() {
		Common.UpdateShadowPlane.call(this,
			this.props.json.viewer.lightSetting.dropShadowOpacity,
			this.props.json.viewer.lightSetting.dropShadowYOffset,
			this.props.json.viewer.lightSetting.spotLightPosY,
			this.props.json.viewer.lightSetting.spotLightFocus,
			this.gltf);
		Common.UpdateModelShadowCaster.call(this, this.gltfShadow, this.gltf);
	}

	updateShadowMapType(_value) {
		this.renderer.shadowMap.type = THREE[_value];
		this.renderer.shadowMap.needsUpdate = true
		if (this.shadowPlane)
			this.shadowPlane.material.needsUpdate = true;
	}

	updateToneMap(_value) {
		this.renderer.toneMapping = THREE[_value];
	}

	updateEmissiveIntensity(_value) {
		if (!this.gltf) return;
		Emissive.CacheMaterials.call(this, this.gltf);
		Emissive.SetIntensity.call(this, _value);
	}

	toggleSidebarButton(_value, _img, _order) {
		if (_value) {
			if (!document.getElementById(_img + "img")) {
				const img = document.createElement("img");
				img.id = _img + "img";
				img.src = this.state.sidebarCache[img.id] || this.props.json.viewer.graphicalElement[_img];
				img.style.cssText = "width: 65px;padding-bottom: 10px;padding-left: 6px;outline: none;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);cursor: pointer;-webkit-touch-callout: none;/* iOS Safari */-webkit-user-select: none;/* Chrome/Safari/Opera */-khtml-user-select: none;/* Konqueror */-moz-user-select: none;/* Firefox */-ms-user-select: none;/* Internet Explorer/Edge*/user-select: none;/* Non-prefixed*/;order:" + _order;

				const div = document.getElementById("menuIcons");
				div.appendChild(img);
				document.getElementById("menu").style.display = "flex";
			}
		} else {
			document.getElementById(_img + "img")?.remove();
			if (document.getElementById("menuIcons").childElementCount == 0) {
				document.getElementById("menu").style.display = "none";
			}
		}
	}

	toggleUI() {
		const sideMenu = document.getElementById("menu");
		const logo = document.getElementById("logoHref");
		if (sideMenu.style.display == "flex" || logo.style.display != "none") {
			sideMenu.style.display = "none";
			logo.style.display = "none";
		} else {
			if (document.getElementById("menuIcons").childElementCount != 0) {
				sideMenu.style.display = "flex";
			}
			logo.style.display = "block";
		}
	}

	updateLogo(_value) {
		if (_value && _value.data) {
			this.state.companyLogo = _value.data;
		}

		if (this.props.json.viewer.headerLogoEnabled && this.props.json.viewer.graphicalElement.logo) {
			document.getElementById("brandLogo").src = this.state.companyLogo || this.props.json.viewer.graphicalElement.logo;
		} else {
			document.getElementById("brandLogo").src = ""
		}
	}

	changeStartingVariant(_leaf) {
		if (this.gltf == undefined) return;
		const variants = this.gltf.root.userData.gltfExtensions['KHR_materials_variants'].variants;
		if (!variants || variants.length < 2) {
			return;
		}
		const variantsLength = variants.length;
		//this.gltf.root.userData.gltfExtensions['KHR_materials_variants']
		//this.gltf.root.parser

		let index = _leaf.getJsonVariable();
		if (index === undefined) {
			index = 1;
		} else {
			index++;
			if (index >= variantsLength) {
				index = 0;
			}
		}
		_leaf.setJsonVariable(index);
		Common.selectGltfVariant(this.gltf.root, index);
	}

	loadStartingVariant(_leaf) {
		if (this.gltf == undefined || !this.gltf.root.userData.gltfExtensions || !this.gltf.root.userData.gltfExtensions['KHR_materials_variants']) return;
		const variants = this.gltf.root.userData.gltfExtensions['KHR_materials_variants'].variants;
		if (!variants || variants.length < 2) {
			return;
		}

		let index = _leaf.getJsonVariable();
		Common.selectGltfVariant(this.gltf.root, index);

	}

	updateUIElements(_value, _id) {
		const elementById = document.getElementById(_id);
		if (_value && _value.data) {
			this.state.sidebarCache[_id] = _value.data;
			if (!elementById) {
				return;
			}
			elementById.src = _value.data;
		} else {
			this.state.sidebarCache[_id] = "";
		}
	}
}