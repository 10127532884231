import { Leaf } from "./Leaf";
import { Composite } from "./Composite";
import { ModelLeaf } from "./ModelLeaf";
import { DropdownLeaf } from "./DropdownLeaf";

let threePlayer;
let editorApp;
export function init(_threePlayer, _editorApp) {
	threePlayer = _threePlayer;
	editorApp = _editorApp;

}

export function create(_insElem, _jsonRef, _jsonPath, _defaultJsonObj, ...x) {

	if (x.length) {
		console.error(x);
	}
	if (_jsonPath) {
		if (_insElem.jsonPath.startsWith(_jsonPath)) {
			_insElem.jsonPath = _insElem.jsonPath.replace(_jsonPath, "")
		}
	}
	if (_insElem.type != "array" && _insElem.jsonPath?.includes("[]")) {
		console.log(_jsonPath, _jsonRef, _insElem);
		console.warn("Element is in array but not properly linked", _insElem)
		return null;

	}

	const commonProps = { key: _insElem.displayName, config: _insElem, jsonRef: _jsonRef, threePlayer: threePlayer };
	const leafProps = { editorApp: editorApp }
	switch (_insElem.type) {
		case "array":
			return <Composite {...commonProps} jsonPath={_jsonPath || ""} defaultJsonObj={_defaultJsonObj} />
		case "model":
			return <ModelLeaf {...commonProps} {...leafProps} />
		case "dropdownInput":
			return <DropdownLeaf {...commonProps} {...leafProps} />
		default:
			return <Leaf {...commonProps} {...leafProps} />
	}
}
