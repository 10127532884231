import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const AddApiKeyModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const [host, setHostName] = useState("");
  const [company, setCompanyname] = useState("");
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    axios
      .get(`/companyroute`, {
        withCredentials: true,
      })
      .then((res) => setCompanyList(res.data.company));
  }, []);

  const HandleGenerateAPIkey = async (e) => {
    e.preventDefault();
    const apiInfo = { company, host };
    try {
      await axios
        .post(`/apiRoute/newapi`, apiInfo, { withCredentials: true })
        .then((res) => {
          if (res.data.success) {
            toast.success(
              `Generate api key successfully`,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            setCompanyname('404')
            setHostName("");
            setModalShow();
            setRefetch(!refetch);
          }
          if (res.data.code === 11000) {
            toast.error("Host already exists", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Generate API Key
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleGenerateAPIkey}>
          <Form.Select
            className="mb-3"
            value={company}
            onChange={(e) => setCompanyname(e.target.value)}
            aria-label="Default select example"
          >
            <option value="404">Select Company</option>
            {companyList.map((company) => (
              <option key={company._id} value={company._id}>
                {company.companyname}
              </option>
            ))}
          </Form.Select>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="text"
              value={host}
              placeholder="Host Name"
              onChange={(e) => setHostName(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Add API Key
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddApiKeyModal;
