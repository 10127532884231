import React from "react";

const toExtract = ["clientProductId"];
const nonStandardLeafs = ["label", "button"];

export class Component extends React.Component {
	static common = {
		client: "debug",
	};

	constructor(props) {
		super(props);

		this.state = { jsonObj: null, jsonIndex: "" }
		// if (nonStandardLeafs.indexOf(props.config.type) != -1) return;
		if (!props.config.jsonPath) return
		let path = props.config.jsonPath.split(".");
		let jsonObj = props.jsonRef;
		for (let i = 0; i < path.length; i++) {
			// console.log(jsonObj, path[i]);
			if (i != path.length - 1) {
				jsonObj = jsonObj[path[i]];
			} else {
				if (toExtract.includes(path[i])) {
					Component.common[path[i]] = jsonObj[path[i]];
				}
				//console.log(jsonObj, path[i]);
				//we do this so we have a REFERENCE to the values of the json
				this.state = { jsonObj: jsonObj, jsonIndex: path[i] };
			}
		}
	}

}
