import React from "react";
import { baseInputField } from "./BaseInputField";

export class DropdownField extends React.Component {
	constructor(props) {
		super(props);
		baseInputField.call(this);
	}

	render() {
		return <>
			<label>{this.props.displayName + " "}</label>
			<select onChange={this.handleChange} >
				{this.props.startsWithEmpty && this.state.value == "" && <option disabled selected value=""></option>}
}
				{this.props.values.map((_entry) => {
					return <option value={_entry.value} selected={this.state.value == _entry.value}>{_entry.display}</option>
				})}
			</select>
		</>;

	}
}

