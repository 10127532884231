import React from "react";
import { baseInputField } from "./BaseInputField";

export class TextInputField extends React.Component {
	constructor(props) {
		super(props);
		baseInputField.call(this);
	}

	render() {
		let isValid = true;
		if (this.props.validation) {
			isValid = this.props.validation(this.state.value);
		}
		return (
			<form
				onSubmit={this.handleSubmit}>
				<label>
					{this.props.displayName + "  "}
					<input
						type={!this.props.isPassword ? "text" : "password"}
						value={this.state.value}
						onChange={this.handleChange}
						onKeyPress={this.handleKeyPress}
						style={this.props.validation ? (isValid ? this.props.inputValidStyle : this.props.inputInvalidStyle) : {}}
					/>
				</label>
			</form>
		);
	}
}
