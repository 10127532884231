import { Leaf } from "./Leaf"

const modelCache = {};

export class ModelLeaf extends Leaf {
	constructor(props) {
		super(props);
		this.state.isModel = true;
		this.state.onValid = (_fileObj) => {
			if (_fileObj && _fileObj.data) {
				modelCache[this.getJsonVariable()] = _fileObj.data;
			}
			this.props.threePlayer.context.loadAndReturnModel.call(this,
				modelCache[this.getJsonVariable()] || this.getJsonVariable(),
				this.addToScene.bind(this))
		};
		Leaf.listeners.push(this);
	}
	componentDidMount() {
		super.componentDidMount();
	}
	componentWillUnmount() {
		super.componentWillUnmount();
		this.removeFromScene();
		Leaf.listeners.splice(Leaf.listeners.indexOf(this), 1);
	}
	render() {
		return super.render()
	}

	addToScene(_gltf) {
		this.removeFromScene();
		this.props.threePlayer.context.addToScene(_gltf.scene);
		this.state.gltf = _gltf;
		this.state.gltfScene = _gltf.scene;
		this.sendMessage("modelListener", "onModelLoad");
	}
	removeFromScene() {
		if (this.state.gltfScene) {
			this.props.threePlayer.context.removeFromScene(this.state.gltfScene);
			this.state.gltfScene = null;
		}
	}
}