import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { userLoggedIn, userLoggedOut } from "../features/authSlice";

function RequireAuth({ children }) {
  const goLogin = useNavigate();
  const { REACT_APP_EXPRESS_BACKEND_URL } =
    process.env;
  const dispatch = useDispatch();
  let location = useLocation();
  const { accessToken, user } = useSelector((state) => state.auth);

  if (accessToken && user) {
    return children;
  } else {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const auth = JSON.parse(token);
      axios
        // .get(`${REACT_APP_EXPRESS_BACKEND_URL}/authroute/verifyjwt`, {
        .get(`/authroute/verifyjwt`, {
          headers: {
            Authorization: `Bearer ${auth?.accessToken}`,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?._id) {
            dispatch(
              userLoggedIn({
                accessToken: auth.accessToken,
                profile: auth.profile,
                user: auth.user,
                timestamp: auth?.timestamp,
              })
            );
            return children;
          } else {
            axios.get(`/authroute/logout`, { withCredentials: true })
              .then((res => {
              }))
            dispatch(userLoggedOut());
            localStorage.removeItem("accessToken");
            return goLogin("/login", {
              state: { from: location },
              replace: true,
            });
          }
        });
    } else {
      axios.get(`/authroute/logout`, { withCredentials: true })
        .then((res => {
        }))
      dispatch(userLoggedOut());
      localStorage.removeItem("accessToken");
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
}

export default RequireAuth;